.page.experience{

    .layer{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: rgba(0,0,0,0.2);
    }

    .msg-header{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20%;
        z-index: 3;

        @media screen and (max-width: 680px) {
            left: 50%;
            transform: translate(-50%);
        }

        @media screen and (max-width: 450px) {
            top: 30%;
            width: 80%;
        }

        h2{
            max-width: 450px;
            font-size: 52px;
            font-weight: 500;
            color: white;

            @media screen and (max-width: 680px) {
                max-width: 100%;
                font-size: 38px;
            }

        }

    }

    .box-types{
        
        .filter{
            text-align: center;
            position: relative;
            margin-bottom: 72px;

            @media screen and (max-width: 1080px) {
                text-align: right;
            }

            @media screen and (max-width: 800px) {
                display: none;
            }

            .button-grid{

                @media screen and (max-width: 1080px) {
                    margin: 0 10px;

                    &:last-child{
                        margin-right: 0;
                        padding-right: 0;
                    }

                }

            }

            &--mobile{
                display: none;

                @media screen and (max-width: 800px) {
                    display: block;
                    margin-bottom: 70px;
                    text-align: center;

                    select{
                        min-width: 200px;
                        padding: 10px;
                        padding-right: 50px;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        -ms-appearance: none;
                        appearance: none;
                        outline: 0;
                        border: 0 !important;
                        background: white;
                        background-image: none;
                        position: relative;
                        -webkit-box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
                        -moz-box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
                        box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                        -ms-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                        background-image:
                        linear-gradient(45deg, transparent 50%, $red 50%),
                        linear-gradient(135deg, $red 50%, transparent 50%),
                        linear-gradient(to right, #ccc, #ccc);
                        background-position:
                        calc(100% - 20px) calc(1em + 2px),
                        calc(100% - 15px) calc(1em + 2px),
                        calc(100% - 2.5em) 0.5em;
                        background-size:
                        5px 5px,
                        5px 5px,
                        1px 1.5em;
                        background-repeat: no-repeat;

                        &::-ms-expand {
                            display: none;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            top: 5px;
                            right: 10px;
                            padding: 0 1em;
                            background: $red;
                            cursor: pointer;
                            pointer-events: none;
                            -webkit-transition: .25s all ease;
                            -o-transition: .25s all ease;
                            transition: .25s all ease;
                        }

                        &:hover{
                            opacity: 0.7;
                            cursor: pointer;
                        }

                    }

                }

            }

        }

    }

    .box-container{

        .box-content{

            .box--driving{

                .white-box{

                    h2{
                        max-width: 140px;
                    }

                }

            }

        }

    }

}