.home{

    .block-header{
        position: relative;

        #video-home{
            position: absolute;
            width: 100%; 
            height: 100%; 
            max-height: 100vh; 
            z-index: 998; 
            background-color: #000000; 
            top: 0; 
            left: 0; 
            overflow: hidden;

            a.skip-video, a.audio-on-off{
                position: absolute;
                right: 30px;
                // bottom: 15%;
                bottom: 160px;
                color: #ffffff;
                text-transform: uppercase;
                letter-spacing: 2px;
                font-size: 12px;
                margin: 0 15px;

                &:hover, &.muted{
                    opacity: 0.6;
                }

                @media screen and (max-width: 450px){
                    right: 10px;
                    bottom: 20px;
                    font-size: 8px;
                }

            }

        }

        #video-play{
            display: none;
            position: absolute;
            top: 45%;
            left: 50%;
            transform: translate(-50%);
            z-index: 2;
            cursor: pointer;

            img{
                opacity: 0.7;
                max-width: 90px;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;

                &:hover{
                    opacity: 1;
                }

            }

        }

        #video-home-vimeo{ 
            display: block;
            // position: fixed;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            width: 100%;
            height: 100vh;
            overflow: hidden;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            
            &>iframe{ 
                width: 100%; 
                height: 100vh; 
            }
        }

    }

    .relevant-container{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        &:hover{
            p{
                color: white;
                background-color: $red;
            }
        }

        .layer-black{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.2);
        }

        h2{
            max-width: 170px;
            font-size: 52px;
            font-weight: 500;
            color: white;

            @media screen and (max-width: 450px) {
                font-size: 42px;
            }

        }

        p{
            position: relative;
            padding: 10px 30px;
            background-color: white;
            display: inline-block;
            width: auto;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
        }

    }

}