.page.events{

    .layer{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: rgba(0,0,0,0.2);
    }

    .msg-header{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20%;
        z-index: 3;

        @media screen and (max-width: 680px) {
            left: 50%;
            transform: translate(-50%);
        }

        @media screen and (max-width: 450px) {
            top: 30%;
            width: 80%;
        }

        h2{
            max-width: 450px;
            font-size: 52px;
            font-weight: 500;
            color: white;

            @media screen and (max-width: 680px) {
                max-width: 100%;
                font-size: 38px;
            }

        }

    }

    .section{
        padding-bottom: 190px;

        @media screen and (max-width: 450px) {
            padding-bottom: 120px;
        }

        .child-container{
            font-size: 0;

            .child{
                width: 30%;
                display: inline-block;
                vertical-align: top;

                &:nth-child(2){
                    margin: 0 5%;
                }

                h3{
                    font-size: 52px;
                    color: black;
                    font-weight: 500;
                    max-width: 200px;
                }

                p{
                    color: rgba(0,0,0,0.58);
                    font-size: 15px;
                    font-weight: 300;
                    line-height: 1.4em;
                }

                @media screen and (max-width: 880px) {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 50px;

                    &:nth-child(2){
                        margin: 0;
                        margin-bottom: 50px;
                    }

                    h3{
                        max-width: 100%;
                        text-align: center;

                        @media screen and (max-width: 450px) {
                            font-size: 42px;
                        }

                    }

                    p{
                        text-align: center;
                    }

                }

            }

        }

    }

    .owl-carousel.owl-carousel--events{
        
        .item{
            max-height: 510px;
        }

        .owl-nav{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin-top: 0;

            span{
                position: absolute;
                bottom: 50px;
                color: white;
                outline: none;
                left: 0;
                margin-left: 50%;
                font-size: 11px;
                transform: translateX(-50%);
            }

            .owl-prev{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 5%;
                background-color: rgba(0,0,0,0.58);
                margin: 0;
                outline: none;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;

                &:hover{
                    background-color: rgba(0,0,0,0.78)
                }

                @media screen and (max-width: 1400px) {
                    width: 10%;
                }

                @media screen and (max-width: 680px) {
                    width: 15%;
                }

                @media screen and (max-width: 500px) {
                    width: 10%;
                    height: 50px;
                    top: initial;
                    bottom: 0;

                    span{
                        bottom: 20px;
                    }

                }

            }

            .owl-next{
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 5%;
                background-color: rgba(0,0,0,0.58);
                margin: 0;
                outline: none;

                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;

                &:hover{
                    background-color: rgba(0,0,0,0.78)
                }

                @media screen and (max-width: 1400px) {
                    width: 10%;
                }

                @media screen and (max-width: 680px) {
                    width: 15%;
                }

                @media screen and (max-width: 500px) {
                    width: 10%;
                    height: 50px;
                    top: initial;
                    bottom: 0;

                    span{
                        bottom: 20px;
                    }

                }

            }

        }

    }

}