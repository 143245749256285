@import 'import/vars';
@import 'import/reset';

@import 'pages/home';
@import 'pages/driving';
@import 'pages/events';
@import 'pages/contacts';
@import 'pages/experience';
@import 'pages/driving-detail';

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");

*{
    font-family: $f;
}

.js [type="file"] {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    white-space: nowrap;
    width: 1px; 
  }
   
  .no-js [type="file"] + label {
    display: none;
  }

.gradient-fixed{
    background: #7dcafd;
    background: -moz-linear-gradient(top, #7dcafd 140px, #0055a0 100%);
    background: -webkit-linear-gradient(top, #7dcafd 140px, #0055a0 100%);
    background: linear-gradient(to bottom, #7dcafd 140px, #0055a0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7dcafd', endColorstr='#0055a0',GradientType=0 );
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

#page-preload{position: fixed;width: 100%;height: 100%;background-color: white;z-index: 9995;top: 0;left: 0;}

#page-preload img.logo-preload{  width: 260px;position: absolute; z-index: 99; left: 50%; top: 50%; margin-left: -130px; margin-top: -90px;
    @media screen and (max-width: 450px) {
        width: 150px;
        margin-left: -75px;
        margin-top: -50px;
    }
}
.loadingbar{position: fixed; top: 0;height: 3px;width: 100%;z-index: 9999;left: 0;opacity: 1;transition: all 0.3s ease-out 0s;}
.loadingbar--hide{opacity: 0;}
.loadingbar .bar{ display: block;height: 100%;background: #444;width: 40%;transition: all 0.3s ease-out 0s;}

body{
    min-height: 100vh;
}

body, html{
    &.overflow{
        overflow: hidden;
    }
}

.wrapper{
    min-height: 98%;
    box-sizing: border-box;
    padding-top: 150px;
}

.white-container{
    background-color: #FFF;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, .1);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, .1);
}

header{
    background-color: white;
    position: fixed;
    display: inline-block;
    z-index: 999;
    top: 0;
    left: 50%;
    text-align: center;
    width: 80%;
    transform: translateX(-50%);
    padding: 20px 25px;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
    box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);

    @media screen and (max-width: 600px) {
        width: 100%;
        left: 0;
        transform: inherit;
        padding: 20px 0;
    }

    &.scroll{
        width: 100%;
        padding: 20px 0;
    }

    .logo{
        position: absolute;
        left: 25px;
        top: 20px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        transition: all 0.3s ease;

        @media screen and (max-width: 1080px) {
            z-index: 10;
        }

        @media screen and (max-width: 450px) {
            top: 22px;
            left: 10px;
        }

        &:hover{
            opacity: 0.7;
        }

        img{
            max-width: 146px;

            @media screen and (max-width: 450px) {
                max-width: 106px;
            }

        }

    }

    .menu{
        width: auto;
        display: inline-block;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        transition: all 0.5s ease;

        @media screen and (max-width: 1080px){
            position: absolute;
            top: -100vh;
            left: 0;
            width: 100%;
            background-color: white;
            z-index: 1;
            opacity: 0;
            -webkit-transition: opacity 0.8s ease;
            -moz-transition: opacity 0.8s ease;
            -o-transition: opacity 0.8s ease;
            -ms-transition: opacity 0.8s ease;
            transition: opacity 0.8s ease;

            &.is-active{
                opacity: 1;
                top: 93px;
                padding-top: 100px;
                height: 100vh;
                overflow-y: auto;

                ul{
                    padding-left: 0;

                    li{
                        display: block;
                        font-size: 32px;
                        padding: 20px 10px;

                        @media screen and (max-width: 650px) {
                            font-size: 25px;
                        }

                        @media screen and (max-width: 400px) {
                            font-size: 18px;
                            padding: 15px 10px;
                        }

                    }

                }

                @media screen and (max-width: 450px) {
                    top: 77px;
                    padding-top: 20px;
                }
                
            }

        }

        ul{
            list-style: none;

            li{
                width: auto;
                display: inline-block;
                text-transform: uppercase;
                font-weight: bold;
                font-size: 12px;
                letter-spacing: 1px;
                padding: 0 49px;

                @media screen and (max-width: 1650px) {
                    padding: 0 29px;   
                }

                @media screen and (max-width: 1220px) {
                    padding: 0 15px;   
                }

                a{

                    &.active{
                        color: $red;
                    }

                }

            }

        }

        #lang{
            display: none;

            @media screen and (max-width: 600px) {
                display: block;
                position: relative;
                right: inherit;
                top: inherit;
                width: 80px;
                margin: 0 auto;
                margin-top: -80px;

                ul{

                    li{
                        font-size: 22px;
                        padding: 5px 10px;
                    }

                }

            }

            @media screen and (max-width: 400px) {
                padding-left: 10px;

                ul{

                    li{
                        font-size: 18px;
                    }

                }

            }

        }

    }

        #lang{
            position: absolute;
            top: 20px;
            right: 240px;
            z-index: 5;
            padding: 20px 5px 10px 20px;

            @media screen and (max-width: 1450px) {
                right: 215px;
            }

            @media screen and (max-width: 1400px) {
                right: 150px;
            }

            @media screen and (max-width: 600px) {
                display: none;
            }

            ul{
                padding-left: 0;
                margin-top: 0;
                margin-bottom: 0;
                list-style: none;
            }

            ul>li{
                display: none;
                padding-right: 15px;
                margin-bottom: 5px;
                font-size: 12px;
                font-weight: bold;

                &.active{
                    display: block;
                    background: url(/app/resources/i/ico-lang.png) no-repeat right center;

                    @media screen and (max-width: 600px) {
                        background: url(/app/resources/i/ico-lang--mobile.png) no-repeat right center;
                    }

                    &:hover{
                        cursor: pointer;

                        a:hover{
                            color: inherit;
                        }
                    }
                }
            }

            &.active{
                background-color: rgba(255, 255, 255, 0.7);

            }
        }

    a.book_button{
        width: auto;
        position: absolute;
        right: 0;
        top: 0;
        padding: 40px 60px 32px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        border-bottom: 5px solid $red;

        &:hover{
            padding-bottom: 29px;
            border-bottom: 8px solid $red;
        }

        @media screen and (max-width: 1400px){
            padding: 40px 30px 32px;
        }

        @media screen and (max-width: 1080px){
            padding: 40px 30px 39px;

            &:hover{
                padding-bottom: 36px;
            }

        }

        @media screen and (max-width: 450px){
            padding: 30px 10px 29px;
        }

    }

    &.is-active{

        a.book_button{

            padding-bottom: 32px;

            &:hover{
                padding-bottom: 32px;
            }

        }

    }

    .hamburger{
        display: none;
        width: 100%;
        box-sizing: border-box;

        @media screen and (max-width: 1080px){
            display: block;
        }

        @media screen and (max-width: 450px){
            padding: 5px;
        }

    }

}

.page{

    &.home .section__col--left p strong{
        font-weight: 500;
        color: $red;
    }

    .owl-carousel{

        .item{
            height: 100vh;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }

    }

    .section{
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 180px 20px 280px;
        box-sizing: border-box;

        @media screen and (max-width: 1080px){
            padding-top: 120px;
            padding-bottom: 200px;
        }

        @media screen and (max-width: 600px){
            padding-top: 80px;
            padding-bottom: 120px;
        }

        &__content{
            font-size: 0;
        }

        &__col{
            display: inline-block;
            vertical-align: top;

            &--left{
                width: 20%;

                @media screen and (max-width: 1080px){
                    width: 100%;
                    margin-bottom: 50px;
                }
                
                p{
                    font-size: 52px;
                    font-weight: 500;

                    @media screen and (max-width: 450px) {
                        font-size: 42px;
                    }

                }

            }
    
            &--right{
                padding: 0 10%;
                width: 60%;
                
                @media screen and (max-width: 1440px){
                    width: 70%;
                    padding: 0 5%;
                }

                @media screen and (max-width: 1080px){
                    width: 100%;
                    padding: 0;
                }
                
            }
    
            &--inside{
                width: 40%;
                margin: 0 5%;
                display: inline-block;
                vertical-align: top;

                @media screen and (max-width: 1080px){
                    margin: 0;
                    width: 45%;

                    &:first-child{
                        margin-right: 10%;
                    }

                }

                @media screen and (max-width: 600px){
                    margin: 0;
                    width: 100%;

                    &:first-child{
                        margin-right: 0;
                    }

                    &:last-child{
                        padding-bottom: 20px;
                    }

                }

                &:last-child{
                    border-bottom: 1px solid $border;
                }
                
                p{
                    font-size: 15px;
                    font-weight: 300;
                    color: rgba(0, 0, 0, 0.58);
                    line-height: 1.8em;

                    strong{
                        color: black;
                    }

                }

            }
    
        }

        &--relevant{
            padding: 108px 20px 160px;
            position: relative;
        }

    }

}

footer{
    text-align: center;
    // padding: 70px 200px 43px;
    padding: 70px 10% 1%;
    font-size: 0;
    -webkit-box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
    box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);

    .piva{
        text-align: center;
        margin-top: 10px;

        p{
            font-size: 13px;
            color: black;
        }

    }

    .col{
        width: 25%;
        display: inline-block;
        vertical-align: top;
        text-align: left;
    
        &--logo{

            @media screen and (max-width: 880px){
                width: 100%;
                text-align: center;
                margin-bottom: 50px;
            }

            img{
                max-width: 146px;
            }

        }

        &--left{
            text-align: center;
            position: relative;

            @media screen and (max-width: 880px){
                width: 50%;
                text-align: center;
            }

            @media screen and (max-width: 500px){
                width: 100%;
                margin-bottom: 50px;

                &:before{
                    content: "";
                    width: 90%;
                    height: 1px;
                    background-color: $border;
                    top: -25px;
                    // transform: translateX(-50%);
                    left: 5%;
                    position: absolute;
                }

                &:after{
                    content: "";
                    width: 90%;
                    height: 1px;
                    background-color: $border;
                    bottom: -25px;
                    // transform: translateX(-50%);
                    left: 5%;
                    position: absolute;
                }

            }

            p{
                font-size: 13px;
                font-weight: 500;
                line-height: 1.2em;

                strong{
                    color: $red;
                    font-weight: bold;
                    font-size: 12px;
                }

            }

        }

        &--right{
            text-align: center;
            position: relative;

            @media screen and (max-width: 880px){
                width: 50%;
                text-align: center;
            }

            @media screen and (max-width: 500px){
                width: 100%;

                &:after{
                    content: "";
                    width: 90%;
                    height: 1px;
                    background-color: $border;
                    bottom: -25px;
                    // left: 50%;
                    left: 5%;
                    // transform: translateX(-50%);
                    position: absolute;
                }

            }

            p{
                font-size: 15px;
                font-weight: 500;
                display: inline-block;
                vertical-align: top;
                max-width: 65px;
                
                a{
                    display: inline-block;
                    color: $red;
                }

            }

            img{
                max-width: 44px;
                margin-right: 15px;
            }

        }

        &--social{
            text-align: right;

            @media screen and (max-width: 880px){
                width: 100%;
                text-align: center;
                margin-top: 50px;
            }

            img{
                max-width: 36px;

                &:last-child{
                    margin-left: 18.5px;
                }

                &.first{
                    margin-left: 0;
                }

            }

        }

    }

    a.link{
        text-align: center;
        display: block;
        font-size: 12px;
        color: $red;
        margin-top: 30px;
    }

    .policy-container{
        text-align: center;
        color: black;
        font-size: 14px;
        margin-top: 40px;
        
        a{
            font-size: 13px;
            color: $red;
        }

    }

}

.page-error{

    &__title{
        max-width: 1024px;
        margin: 0 auto;
        padding: 0 25px;
        padding-top: 150px;
        text-align: left;
        font-size: 35px;
        font-family: $f;
        color: #323746;
        margin-bottom: 40px;
        text-transform: uppercase;
    }

    &__text {
        max-width: 1024px;
        margin: 0 auto;
        padding: 0 25px;
        text-align: left;
        font-size: 35px;
        font-family: $f;
        color: #323746;
        margin-bottom: 80px;
        text-transform: uppercase;
    }

}
