.page.driving{

    .section{
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;

        @media screen and (max-width: 1080px) {
            padding-top: 180px;
        }

    }

    .box-types{
        // margin: 0 2.5%;
        margin: 0 1.5%;
        
        .filter{
            text-align: center;
            position: relative;
            margin-bottom: 72px;

            @media screen and (max-width: 880px) {
                text-align: right;
            }

            @media screen and (max-width: 580px) {
                display: none;
            }

            .button-grid{
                position: relative;
                max-width: 140px;
                margin: 0 30px;
                background-color: none;
                border: none;
                outline: none;
                font-size: 22px;
                color: $red;
                font-weight: 500;
                text-align: left;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;
                background-color: white;

                @media screen and (max-width: 880px) {
                    margin: 0 10px;

                    &:last-child{
                        margin-right: 0;
                        padding-right: 0;
                    }

                }

                @media screen and (max-width: 580px) {
                    display: none;

                    // &:first-child{
                    //     display: block;
                    // }

                }

                &:after{
                    opacity: 0;
                    content: "";
                    position: absolute;
                    bottom: -10px;
                    height: 5px;
                    width: 100%;
                    background-color: $red;
                    left: 0;
                    -webkit-transition: all 0.3s ease;
                    -moz-transition: all 0.3s ease;
                    -o-transition: all 0.3s ease;
                    -ms-transition: all 0.3s ease;
                    transition: all 0.3s ease;
                }
                
                &:hover{
                    cursor: pointer;
                    opacity: 0.7;
                }

                &:first-child{
                    position: absolute;
                    top: 20px;
                    left: 0;
                    font-size: 22px;
                    color: black;
                    font-weight: 500;
                    margin: 0;
                    padding-left: 0;
                }

                span{
                    color: black;
                }

                &.active{
                    
                    &:after{
                        opacity: 1;
                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                        -ms-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                    }

                }

            }

            &--mobile{
                display: none;

                @media screen and (max-width: 580px) {
                    display: block;
                    margin-bottom: 70px;
                    text-align: center;

                    select{
                        min-width: 200px;
                        padding: 10px;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        -ms-appearance: none;
                        appearance: none;
                        outline: 0;
                        border: 0 !important;
                        background: white;
                        background-image: none;
                        position: relative;
                        -webkit-box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
                        -moz-box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
                        box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                        -ms-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                        background-image:
                        linear-gradient(45deg, transparent 50%, $red 50%),
                        linear-gradient(135deg, $red 50%, transparent 50%),
                        linear-gradient(to right, #ccc, #ccc);
                        background-position:
                        calc(100% - 20px) calc(1em + 2px),
                        calc(100% - 15px) calc(1em + 2px),
                        calc(100% - 2.5em) 0.5em;
                        background-size:
                        5px 5px,
                        5px 5px,
                        1px 1.5em;
                        background-repeat: no-repeat;

                        &::-ms-expand {
                            display: none;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            top: 5px;
                            right: 10px;
                            padding: 0 1em;
                            background: $red;
                            cursor: pointer;
                            pointer-events: none;
                            -webkit-transition: .25s all ease;
                            -o-transition: .25s all ease;
                            transition: .25s all ease;
                        }

                        &:hover{
                            opacity: 0.7;
                            cursor: pointer;
                        }

                    }

                }

            }

        }

    }

    .box-container{
        margin-bottom: 316.5px;

        @media screen and (max-width: 500px) {
            margin-bottom: 180px;
        }

        .box-content{
            font-size: 0;

            .box--driving{
                width: auto;
                display: inline-block;
                // width: 20%;
                // margin: 0 2.5%;
                width: 22%;
                margin: 0 1.5%;
                margin-bottom: 50px;
                box-sizing: border-box;

                @media screen and (max-width: 1080px) {
                    // width: 28.333333%;
                    width: 30.333333%;
                }

                @media screen and (max-width: 800px) {
                    // width: 45%;
                    width: 47%;
                }

                @media screen and (max-width: 500px) {
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                    padding: 0 10px;
                }

                .background{
                    width: 100%;
                    padding: 100px 0;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }

                .white-box{
                    position: relative;
                    background-color: white;
                    width: 80%;
                    margin-top: -50px;
                    padding: 18px 30px;
                    padding-right: 0;
                    -webkit-box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
                    -moz-box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
                    box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);

                    h2{
                        max-width: 100px;
                        font-size: 22px;
                        margin-top: 0;
                        margin-bottom: 10px;
                        color: $red;

                        span{
                            color: black;
                        }

                    }

                    p{
                        margin-bottom: 36px;
                        text-transform: uppercase;
                        font-weight: 400;
                        font-size: 9px
                    }

                    a.more-info{
                        position: absolute;
                        bottom: 14px;
                        right: 20px;
                        font-size: 20px;
                        font-weight: 300;
                        color: $red;
                        -webkit-transition: all 0.3s ease;
                        -moz-transition: all 0.3s ease;
                        -o-transition: all 0.3s ease;
                        -ms-transition: all 0.3s ease;
                        transition: all 0.3s ease;

                        &:hover{
                            font-size: 25px;
                        }

                        &--plus{
                            font-size: 30px;

                            &:hover{
                                font-size: 38px;
                            }

                        }

                    }

                }

                &:hover{

                    a.more-info{
                        font-size: 25px;

                        &--plus{
                            font-size: 38px;
                        }

                    }
                
                }

            }

        }

    }

}