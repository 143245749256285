.page.contacts{

    .layer{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: rgba(0,0,0,0.2);
    }

    .msg-header{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20%;
        z-index: 3;

        @media screen and (max-width: 680px) {
            left: 50%;
            transform: translate(-50%);
        }

        @media screen and (max-width: 450px) {
            top: 30%;
            width: 80%;
        }

        h2{
            max-width: 450px;
            font-size: 52px;
            font-weight: 500;
            color: white;

            @media screen and (max-width: 680px) {
                max-width: 100%;
                font-size: 38px;
            }

        }

    }

    .section{
        padding: 180px 190px 280px;

        @media screen and (max-width: 1200px) {
            padding: 180px 100px 280px;
        }

        @media screen and (max-width: 980px) {
            padding: 180px 50px 280px;
        }

        @media screen and (max-width: 800px) {
            padding: 100px 20px 200px;
        }

        @media screen and (max-width: 450px) {
            padding-bottom: 150px;
        }

        .box{

            h3{
                margin: 0 auto;
                margin-bottom: 53px;
                font-size: 22px;
                max-width: 400px;
            }

            &-left{
                width: 35%;
                float: left;

                @media screen and (max-width: 800px) {
                    float: none;
                    width: 100%;
                    max-width: 400px;
                    margin: 0 auto;
                    margin-top: 80px;
                }

                img{
                    max-width: 36px;
                }

                a{
                    display: inline-block;
                    vertical-align: middle;
                }

                p{
                    line-height: 1.2em;
                    font-size: 16px;

                    &:last-child{
                        margin-bottom: 72px;
                    }

                    strong{
                        font-weight: 500;
                        color: $red;
                    }

                }

                .social-container{

                    &--first{
                        margin-top: 72px;
                        margin-bottom: 20px;
                    }

                    img{
                        margin-right: 15px;
                    }

                }

            }

            &-right{
                float: right;
                width: 65%;

                @media screen and (max-width: 800px) {
                    float: none;
                    width: 100%;
                }

                h3{
                    margin-bottom: 23px;
                }

            }

        }

        .contact-form{
            max-width: 400px;
            margin: 0 auto;

            .form__feedback {
                .content {
                    padding-top: 20px;
                    .message {
                        font-size: 17px;
                        color: black;
                        margin-bottom: 10px;
                    }
                }
            }

            span.err{
                color: $red;
            }

            .input{

                &.name, &.surname{
                    width: 45%;
                    display: inline-block;
                    vertical-align: top;
                }

                &.name{
                    margin-right: 10%;
                }

            }

            input[type="text"]{
                width: 100%;
                font-size: 15px;
                background: none;
                border: none;
                border-bottom: 1px solid $border;
                outline: none;
                padding: 20px 0 10px;
                margin-top: 20px;
                -webkit-appearance: none;
                border-radius: 0;

                &.name, &.surname{
                    width: 100%;
                }

                &::placeholder{
                    color: rgba(0,0,0,0.33);
                    font-size: 14px;
                }

            }
            
            textarea{
                box-sizing: border-box;
                resize: none;
                font-size: 15px;
                color: black;
                width: 100%;
                padding: 20px 10px 10px;
                margin-top: 10px;
                background: none;
                border: 1px solid $border;
                outline: none;
                margin-top: 60px;
                min-height: 144px;
            }
        
            input[type="submit"]{
                margin-top: 40px;
                font-weight: 500;
                font-size: 16px;
                color: white;
                background: $red;
                padding: 8px 71px 10px;
                letter-spacing: 1px;
                border: none;
                outline: unset;
                cursor: pointer;
            }
            
            .privacy {
                margin: 15px 0 0 0;
                .checkbox {
                    span.err{
                        margin-top: 10px;
                        position: absolute;
                    }
                    label.checkbox {
                        display: block;
                        position: relative;
                        bottom: unset;
                        span {
                            a {
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        
            .send{
                display: inline-block;
                position: relative;
                -webkit-transition: all 0.3s ease-in;
                -o-transition: all 0.3s ease-in;
                -moz-transition: all 0.3s ease-in;
                transition: all 0.3s ease-in;
                
                &:after{
                    content: '';
                    position: absolute;
                    height: 5px;
                    background-color: none;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    -webkit-transition: all 0.15s ease-in;
                    -o-transition: all 0.15s ease-in;
                    -moz-transition: all 0.15s ease-in;
                    transition: all 0.15s ease-in;
                }
        
                &:hover{
                    opacity: 0.7;
                    
                    &:after{
                        width: 100%;
                        -webkit-transition: all 0.15s ease-in;
                        -o-transition: all 0.15s ease-in;
                        -moz-transition: all 0.15s ease-in;
                        transition: all 0.15s ease-in;
                    }
                }
            }
        
            label{
                font-size: 14px;
                position: absolute;
                top: 10px;
                left: 0px;
                opacity: 0.8;
                -webkit-transition: all 0.15s ease-in;
                -o-transition: all 0.15s ease-in;
                -moz-transition: all 0.15s ease-in;
                transition: all 0.15s ease-in;
                display: none;
            }
        
            .input,.textarea{
                position: relative;
            }

            .textarea{

                label{
                    display: block;
                    top: 35px;
                    color: rgba(0,0,0,0.43);
                }

            }

            textarea,
            input.text,
            input[type="text"],
            input[type="button"],
            input[type="submit"],
            .input-checkbox {
                -webkit-appearance: none;
                border-radius: 0;
            }

        }

    }

}

.init-map{
    min-height: 500px;
}
