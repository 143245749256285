/*
* FONTS
*/

$f : 'Roboto', sans-serif;


/*
*   COLORS
*/

$red: #E31608;
$black: #0D0C0D;
$border: rgba(112,112,112, 0.35);