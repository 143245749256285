.book-button{

    &--fixed{
        position: fixed;
        bottom: -300px;
        right: 2%;
        z-index: 10;
        -webkit-transition: all 1.5s ease;
        -moz-transition: all 1.5s ease;
        -o-transition: all 1.5s ease;
        -ms-transition: all 1.5s ease;
        transition: all 1.5s ease;

        @media screen and (max-width: 850px){
            right: 0;
        }

        @media screen and (max-width: 760px) {
            display: none;
        }

        &.visible{
            bottom: 0;
        }

        a{
            display: block;
            width: auto;
            padding: 27px 101px 33px;
            background-color: $red;
            color: white;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px;
            text-transform: uppercase;

            @media screen and (max-width: 1024px){
                padding: 23px 41px 28px;
            }

            &:hover{
                opacity: 0.9;
            }

        }
    
    }

    &--relative{
        display: none;

        @media screen and (max-width: 760px) {
            display: block;
        }

        a{
            display: block;
            text-align: center;
            padding: 27px 101px 33px;
            background-color: $red;
            color: white;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 1px;
            text-transform: uppercase;

            &:hover{
                opacity: 0.9;
            }

        }
    
    }

}

.page.driving-detail{

    .container-services{
        background-color: #F5F5F2;
    }

    .section{
        padding-bottom: 0;

        &--services{
            padding-bottom: 190px;

            @media screen and (max-width: 600px) {
                padding-bottom: 50px;
            }

        }

        .box-container{
            margin-top: 124.5px;
            border-bottom: 1px solid rgba(112,112,112,0.35);
            padding-bottom: 130px;

            @media screen and (max-width: 450px) {
                margin-top: 80px;
                padding-bottom: 85px;
            }

            &:first-child{
                margin-top: 0;
            }

            &:last-child{
                border-bottom: none;
            }

            iframe{
                width: 100%;
                margin-top: 30px;
            }

        }

        .col{
            display: inline-block;
            vertical-align: top;

            &--left{
                width: 24%;
                margin-right: 5%;

                @media screen and (max-width: 850px) {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 0;
                }

            }

            &--full{
                width: 65%;

                @media screen and (max-width: 850px) {
                    width: 100%;
                }

            }

            &--3{
                margin-left: 29%;
                margin-top: 30px;
                margin-bottom: 30px;

                @media screen and (max-width: 850px) {
                    width: 100%;
                    margin-left: 0;
                    margin-top: 0;
                }

                @media screen and (max-width: 600px) {
                    margin-top: 30px;
                }

            }

            &--half{
                width: 30%;

                @media screen and (max-width: 850px) {
                    width: 44%;
                }

                @media screen and (max-width: 600px) {
                    width: 100%;
                }

            }

            &--1{
                margin-right: 10%;

                @media screen and (max-width: 850px) {
                    margin-bottom: 30px;
                }

                @media screen and (max-width: 600px) {
                    margin-right: 0px;
                }

            }

            h2{
                max-width: 180px;
                font-size: 52px;
                font-weight: 500;
                margin-top: 0;

                @media screen and (max-width: 450px) {
                    font-size: 42px;
                }

            }

            p{
                font-size: 15px;
                font-weight: 300;
                color: rgba(0,0,0,0.58);
                line-height: 1.6em;

                strong{
                    font-weight: 500;
                }

            }

            ul{
                padding-left: 0;
                list-style-position: inside;

                li{
                    font-size: 15px;
                    font-weight: 300;
                    line-height: 1.6em;
                    color: rgba(0,0,0,0.70);
                }

            }

        }

    }

    .layer{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: rgba(0,0,0,0.2);
    }

    .msg-header{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20%;
        z-index: 3;

        @media screen and (max-width: 680px) {
            left: 50%;
            transform: translate(-50%);
        }

        @media screen and (max-width: 400px) {
            left: 40%;
            top: 30%;
        }

        @media screen and (max-width: 350px) {
            top: 20%;
        }

        h2{
            max-width: 250px;
            font-size: 52px;
            font-weight: 500;
            color: white;
            margin-bottom: 68px;

            @media screen and (max-width: 680px) {
                max-width: 100%;
                font-size: 38px;
            }

        }

        .product-type{
            background-color: white;
            width: auto;
            display: inline-block;
            padding: 9px 30px 10px;
            -webkit-box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
            -moz-box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);
            box-shadow: 0px 3px 60px 1px rgba(0,0,0,0.25);

            p{
                color: black;
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 400;
            }

        }

    }

    .owl-carousel.owl-carousel--events{
        
        .item{
            max-height: 510px;
        }

        .owl-nav{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            margin-top: 0;

            span{
                position: absolute;
                bottom: 50px;
                color: white;
                outline: none;
                left: 0;
                margin-left: 50%;
                transform: translateX(-50%);
                font-size: 11px;
            }

            .owl-prev{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 5%;
                background-color: rgba(0,0,0,0.58);
                margin: 0;
                outline: none;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;

                &:hover{
                    background-color: rgba(0,0,0,0.78)
                }

                @media screen and (max-width: 1400px) {
                    width: 10%;
                }

                @media screen and (max-width: 680px) {
                    width: 15%;
                }

                @media screen and (max-width: 500px) {
                    width: 10%;
                    height: 50px;
                    top: initial;
                    bottom: 0;

                    span{
                        bottom: 20px;
                    }

                }

            }

            .owl-next{
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                width: 5%;
                background-color: rgba(0,0,0,0.58);
                margin: 0;
                outline: none;

                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                transition: all 0.3s ease;

                &:hover{
                    background-color: rgba(0,0,0,0.78)
                }

                @media screen and (max-width: 1400px) {
                    width: 10%;
                }

                @media screen and (max-width: 680px) {
                    width: 15%;
                }

                @media screen and (max-width: 500px) {
                    width: 10%;
                    height: 50px;
                    top: initial;
                    bottom: 0;

                    span{
                        bottom: 20px;
                    }

                }

            }

        }

    }

}